<template>
  <el-dialog
    :title="title"
    :visible.sync="modalVisible"
    width="36%"
    :before-close="handleClose"
    :close-on-click-modal="false">
    <el-form v-loading="loading" role="form" ref="eventLocationForm" :model="form" :rules="rules">
      <div class="mt--4">
        <el-form-item prop="name">
          <p class="form-label-font mb-2">Title*</p>
          <el-input
            name="Title" label="Title*" v-model="form.name"
            placeholder="Title">
          </el-input>
        </el-form-item>
        <el-form-item prop="address">
          <p class="form-label-font mb-2" >Location*</p>
          <el-input
            name="Location" v-model="form.address"
            placeholder="Location">
          </el-input>
        </el-form-item>
        <el-form-item prop="status">
          <p class="form-label-font mb-2"> Status*</p>
          <el-select
            class="w-100" label="Status" v-model="form.status">
            <el-option class="select-danger" value="0" label="Inactive"> </el-option>
            <el-option class="select-danger" value="1" label="Active"> </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="text-right mt-4">
        <base-button class="cancel-btn" type="secondary" @click="handleClose">Cancel</base-button>
        <base-button class="btn-primary-action pl-5 pr-5 br-4" @click="handleSubmit" type="success">Save</base-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: "EventLocationModal",
  props: ['title','modalVisible','editFlag','formData','loading'],
  watch: {
    formData: function ({name,address,status,id}){
      this.form.name = name
      this.form.address = address
      this.form.status = String(status)
      this.id = id ?? null
    }
  },
  data() {
    return {
      form: {
        name: '',
        address: '',
        status: '1'
      },
      id: null,
      rules:{
        name:[
          {required:true,message:'Please Enter the Title for place',trigger:'blur'}
        ],
        address:[
          {required:true,message:'Please Enter the Title for place',trigger:'blur'}
        ],
      }
    }
  },
  methods: {
    clearForm() {
      if (this.$refs['eventLocationForm'] != null)
        this.$refs.eventLocationForm.resetFields(['name', 'status', 'location'])
    },
    handleClose(){
      this.$emit('close',false);
    },
    handleSubmit(){
      let validity = true
      this.$refs['eventLocationForm'].validate((valid)=>{
        if (!valid){
          validity = false
          return false
        }
      })
      if (validity) {
        if (this.editFlag) this.$emit('submit', this.form, this.id)
        else this.$emit('submit', this.form, null)
      }
    }
  }
}
</script>

<style scoped>

.form-label-font{
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 600;
}

</style>
