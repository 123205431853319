<template>
  <div>
    <div class="ml-4 pl-3">
      <route-back-button/>
    </div>
    <div v-loading="loader.form" class="m-0 px-3 pt-4 pb-0 pr-2">
      <div class="row m-0 justify-content-between align-items-center mb-2">
        <h1 class="pl-3">View Event</h1>
        <router-link v-if="EventForm.admin_imported == 0" :to="`/event-calender/form/${this.$route.params.id}`">
          <el-button class="mr-3 py-2 px-5 white-outline-button">Edit</el-button>
        </router-link>
      </div>
      <div class="row m-0 ">
        <div class="col-12 col-md-6">
          <card>
            <div class="d-flex flex-row mt-2">
              <p class="ml-2 mr-4 card-heading mt-1">
                Event Detail
              </p>
              <p class="patient-status pl-3 pr-3 pt-1 pb-1"
                 :class="EventForm.status === 0 ? 'inactive' : 'active'">
                {{ EventForm.status ?'Active' : 'Inactive' }}
              </p>
            </div>
            <div class="row m-0">
              <div class="col-6">
                <p class="form-field">Event Name</p>
                <p class="form-field">Event Type</p>
                <p class="form-field">Start Time</p>
                <p class="form-field">End Time</p>
                <p class="form-field">Date</p>
                <p class="form-field">Location</p>
                <p class="form-field">Created Date</p>
                <p class="form-field">Modified Date</p>
                <p class="form-field">Venue</p>
                <p class="form-field">Weight Loss Plan</p>
              </div>
              <div class="col-6">
                <p class="value">{{EventForm.name}}</p>
                <p class="value">{{ (EventForm.type==1)?'Fixed Date':'Weekly' }}</p>
                <p class="value">{{ EventForm.startTime }}</p>
                <p class="value">{{ EventForm.endTime }}</p>
                <p class="value">{{ EventForm.date | getFormattedDate }}</p>
                <p class="value">{{ (EventForm.location==="")?'Other':EventForm.location }}</p>
                <p class="value">{{ EventForm.created_at | getFormattedDate }}</p>
                <p class="value">{{ EventForm.modified_at | getFormattedDate }}</p>
                <p class="value">{{venue_types[EventForm.venue_type]}}</p>
                <p class="value">{{weight_loss_types[EventForm.weight_loss_type]}}</p>
              </div>
            </div>
          </card>
        </div>
        <div class="col-12 col-md-6">
          <card >
            <div class="d-flex flex-row mt-2">
              <p class="ml-2 mr-4 card-heading mt-1">
                Event Description
              </p>
            </div>
            <div class="row m-0">
              <p class="ml-2 overflow-auto event-description-max-height"> <span class="value" v-html="EventForm.description"></span> </p>
            </div>
          </card>
        </div>
      </div>
      <div class="row m-0">
        <div class="col-12 col-md-6">
          <card>
            <div class="d-flex flex-row mt-2">
              <p class="ml-2 mr-4 card-heading mt-1">
                Groups to Remind
              </p>
            </div>
            <div class="row m-0">
              <div v-for="stage in EventForm.stages" :key="stage.id" class="col-6">
                <p class="value"> {{groups[stage]}} </p>
              </div>
            </div>
            <div class="d-flex flex-row mt-2">
              <p class="ml-2 mr-4 card-heading mt-1">
                Custom 5 Minute Reminder Message
              </p>
            </div>
            <div class="row m-0">
              <div class="col-6">
                <p class="value"> {{EventForm.notification_message}} </p>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import moment from "moment"

export default {
  name: "EventDetail",
  components: {RouteBackButton: BackButton},
  data() {
    return {
      loader: {
        form: false
      },
      EventForm: {
        name: '',
        type: '',
        description: '',
        startTime: '',
        endTime: '',
        date: '',
        stages: [],
        reminder: false,
        location: '',
        address: '',
        email: '',
        status: '1',
        created_at: '',
        modified_at: '',
        venue_types: '',
        weight_loss_types: '',
        notification_message: '',
        admin_imported: 1,
      },
      groups: {
        '25': 'Considering Surgery',
        '50': 'Pre - Op',
        '75': 'Post - Op',
        '150': 'Non - Surgical',
      },
      venue_types: {
        '': 'All',
        '1': 'In-Person',
        '2': 'Virtual',
      },
      weight_loss_types:{
        '': 'All',
        '1': 'Surgical',
        '2': 'Non-Surgical',
      }
    }
  },
  mounted() {
    this.fetchEventRequest()
  },
  filters: {
    /**
     * Reformat created date
     * @param date
     * @returns {string}
     */
    getFormattedDate: function (date) {
      return date ? moment.utc(date).local().format('MMM DD, YYYY'): 'N/A'
    }
  },
  methods: {

    addComma(date){
      return date.substring(0,6)+','+date.substring(6)
    },

    setValues(event) {
      this.EventForm.name = event.event_title
      this.EventForm.type = ''+event.type
      this.EventForm.description = event.event_description
      this.EventForm.startTime = event.time
      this.EventForm.endTime = event.end_time
      this.EventForm.date = event.date
      let stages = event.stage.split(',')
      stages = stages.filter((value)=>{return !!value})
      for (const stage of stages) {
        this.EventForm.stages.splice(0,0,stage)
      }
      this.EventForm.reminder = !!(event.push_notification)
      this.EventForm.location = (event.location_relation) ? event.location_relation.name : ''
      this.EventForm.address = event.location
      this.EventForm.email = event.email
      this.EventForm.status = event.status ? 1 : 0
      this.EventForm.created_at = event.created
      this.EventForm.modified_at = event.modified
      this.EventForm.venue_type = event.venue_type ?? ''
      this.EventForm.weight_loss_type = event.weight_loss_type ?? ''
      this.EventForm.notification_message = event.custom_reminder_message ?  event.custom_reminder_message: '-'
      this.EventForm.admin_imported = event.admin_imported ?? false
    },

    fetchEventRequest() {
      this.loader.form = true
      axios.get(this.$store.getters.getBaseUrl + `/api/event-calendar/${this.$route.params.id}`)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.setValues(response.data.data)
        })
        .catch((error) => {
          this.$notify.error({
            title: 'Error',
            message: 'Event might have been deleted'
          })
          this.$router.replace('/event-calender')
          console.log(error)
        })
        .finally(() => this.loader.form = false)
    }
  }
}
</script>

<style scoped>

.value{
  text-align: left;
  font: normal normal normal 14px/26px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
}

.form-field{
  font: normal normal normal 14px/26px Open Sans;
  letter-spacing: 0;
  color: #8898AA;
}

.card-heading{
  text-align: left;
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
  text-transform: uppercase;
}

.patient-status {
  box-shadow: 0 3px 2px #E9ECEF0D;
  border-radius: 20px;
  opacity: 1;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0.48px;
  color: #FFFFFF;
}

.active {
  background: #94B527 0 0 no-repeat padding-box;
}

.inactive {
  background: #BB2255 0 0 no-repeat padding-box;
}

.event-description-max-height {
  max-height: 405px;
}
</style>
