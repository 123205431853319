<template>
  <div class="root">
    <div class="ml-4">
      <route-back-button/>
    </div>
    <div class="mx-4 mt-4">
      <div class="row mx-0 justify-content-between align-items-center">
        <h1 class="m-0">List of Locations</h1>
        <base-button class="btn-secondary-action button-font title-btn-padding"
                     :disabled="loaders.locations"
                     @click="handleAddNew">
          Add New
        </base-button>
      </div>
      <div class="mt-4 cardStyle" v-loading="loaders.locations">
        <div class="row mx-0 justify-content-between align-items-center">
          <div id="select-option-container" class="mt-4 ml-4"
               :class="{invisible: (!selectedRows.length)}">
            <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                         @click="promptMultiRemove"
                         :loading="loaders.checkboxButton.Delete"
                         :disabled="loaders.contacts"
            >Delete
            </base-button>
            <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                         @click="handleActivate"
                         :loading="loaders.checkboxButton.Activate"
                         :disabled="loaders.contacts"
            >Activate
            </base-button>
            <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                         @click="handleDeactivate"
                         :loading="loaders.checkboxButton.DeActive"
                         :disabled="loaders.contacts"
            >Deactivate
            </base-button>
          </div>
          <div class="d-inline-block mr-4 mb-3 mt-2" v-if="isProgramAdmin">
            <label class="form-control-label">Timezone</label>
            <el-select
              class="w-100 min-width-270"
              v-model="response.timezone"
              placeholder="Select Timezone"
              @change="updateTimezone"
            >
              <el-option
                v-for="timezone in timezones"
                class="select-danger"
                :value="timezone.value"
                :label="timezone.label"
                :key="timezone.label">
              </el-option>
            </el-select>
          </div>
          <div class="d-inline-block mr-4" v-else-if="isProgramUser && getTimeZone">
            <span>Timezone: <span class="font-weight-bold">{{ getTimeZone }}</span></span>
          </div>
        </div>
          <el-table
            class="table-responsive table-flush"
            header-row-class-name="thead-light"
            ref="multipleSelectionTable"
            :data="response.locations"
            @selection-change="contactsMultiSelect"
          >
            <el-table-column
              v-for="(column,index) in tableColumns"
              :key="column.label"
              v-bind="column"
            >
              <template v-if="index===3" scope="scope">
              <span :class="[column.prop, getColumn(scope.row, column)]">
                <div class="d-inline-block indicator" :class="getColumn(scope.row, column)"></div>
                {{ getColumn(scope.row, column) }}
              </span>
              </template>
              <template v-else-if="index!==0" scope="scope">
                <span>{{ getColumn(scope.row, column) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-bind="lastColumn">
              <div slot-scope="{ $index, row }" class="d-flex">
                <img
                  class="pointer mr-2"
                  @click="handleEdit($index, row)"
                  src="/img/icons/buttons/edit.svg"
                  alt="Edit"
                />
                <img
                  class="pointer"
                  @click="handleDelete($index, row)"
                  src="/img/icons/buttons/delete.svg"
                  alt="Delete"
                />
              </div>
            </el-table-column>
          </el-table>
          <div class="col-12 d-flex justify-content-end flex-wrap">
            <custom-pagination
              class="mt-4 pagination-no-border float-right"
              v-model="request.pagination.page"
              :per-page="request.pagination.perPage"
              :total="request.pagination.total"
              @input="changePage"
            >
            </custom-pagination>
          </div>
      </div>
      <div class="modals">
        <remove-modal
          :loader="loaders.checkboxButton.Delete"
          :modal-text="removeModalText"
          :removeId="removeId"
          @onRemove="removeContact"
          @onRemoveMultiple="deleteContact"
        />
        <event-location-modal
          :title="modals.location.title"
          :modal-visible="modals.location.visibility"
          :form-data="modals.location.formData"
          :edit-flag="modals.location.editFlag"
          :loading="modals.location.loader"
          @close="modals.location.visibility=false"
          @submit="createLocationRequest"
          ref="eventLocationModal"
        />
      </div>
    </div>

  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import {Table, TableColumn} from "element-ui";
const RemoveModal = () => import(/* webpackChunkName: "contactUsRemoveModal" */ "@/components/Modals/RemoveModal.vue");
import CustomPagination from "../../../Components/Pagination/CustomPagination";
import EventLocationModal from "../../../Components/Event/EventLocationModal";
export default {
  name: "EventLocationList",
  components: {
    EventLocationModal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal, CustomPagination,
    RouteBackButton: BackButton
  },
  data(){
    return {
      loaders: {
        locations: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      modals:{
        location:{
          title: 'Add Location',
          visibility: false,
          editFlag: false,
          loader: false,
          formData: {
            name: '',
            address: '',
            status: '1'}
          }
      },
      timezones: [
        {
          value: null, label: 'Select Timezone',
        },
        {
          value: 'America/New_York', label: 'Eastern Daylight Time New York (GMT-4)',
        },
        {
          value: 'America/Chicago', label: 'Central Daylight Time Chicago (GMT-5)',
        },
        {
          value: 'America/Denver', label: 'Mountain Daylight Time Denver (GMT-6)',
        },
        {
          value: 'America/Phoenix', label: 'Mountain Standard Time Phoenix (GMT-7)',
        },
        {
          value: 'America/Los_Angeles', label: 'Pacific Daylight Time Los Angeles (GMT-7)',
        },
        {
          value: 'America/Anchorage', label: 'Alaska Daylight Time Anchorage (GMT-8)',
        },
        {
          value: 'Pacific/Honolulu', label: 'Hawaii-Aleutian Standard Time (GMT-10)',
        },
        {
          value: 'Australia/Sydney', label: 'Australian Eastern Standard Time Sydney (GMT+10)',
        },
      ],
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      response: {
        locations: [],
        timezone: ''
      },
      request : {
        pagination : {
          page:1,
          currentPage:1,
          perPage:20,
          total:1,
        }
      },
      tableColumns: [
        {
          prop: "selected",
          type: 'selection',
          width: 50,
        },
        {
          prop: "name",
          label: "Title",
          minWidth: 160,
        },
        {
          prop: "address",
          label: "Location",
          minWidth: 180,
        },
        {
          prop: "status",
          label: "Status",
          minWidth: 110,
        },
      ],
      lastColumn: {
        label: "Actions",
        minWidth: 94,
      },
    }
  },
  methods: {
    /**
     * Update program timezone
     */
    updateTimezone() {
      let vm = this
      vm.loaders.locations = true
      const payload = {
        timezone: this.response.timezone
      }
      vm.$store.dispatch('_updateProgramTimezone', payload)
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Program Timezone',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.locations = false
        })
    },

    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    getStatus(status) {
      if (status === 0) {
        return 'Inactive'
      }
      return 'Active';
    },

    contactsMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    clearSelection() {
      this.$refs.multipleSelectionTable.clearSelection();
    },

    handleEdit(index, row) {
      this.modals.location.formData = row
      this.modals.location.editFlag = true
      this.modals.location.title = 'Edit Location'
      this.modals.location.visibility = true
    },

    handleAddNew(){
      this.modals.location.editFlag = false
      this.modals.location.title = 'Add Location'
      this.$refs.eventLocationModal.clearForm()
      this.modals.location.formData = {address: '',name: '', status: '1'}
      this.modals.location.visibility = true
    },

    getColumn(row, column) {
      if (column.prop === 'status') {
        return this.getStatus(row.status);
      } else {
        return row[column.prop]
      }
    },

    handleDelete(index, row) {
      this.promptRemove(row)
    },

    promptRemove(row) {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove this Event Location.';
      this.removeId = row.id;
    },

    promptMultiRemove() {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove these Event Locations.';
      this.removeId = this.extractSelectedIds;
    },

    removeContact(id) {
      this.deleteRequest(id)
    },

    deleteContact() {
      this.deleteRequest(this.removeId)
    },

    changePage(page){
      this.request.pagination.page = page;
      this.fetchLocationRequest();
    },

    errorRoutine(error){
      this.$notify.error({
        title: 'Error',
        message: 'Something went wrong please try again later'
      })
      console.error(error.response)
    },

    fetchLocationRequest() {
      let vm = this;
      vm.loaders.locations = true
      this.clearSelection()
      axios.get(this.$store.getters.getBaseUrl + '/api/event-calendar/location')
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.locations = response.data.data.locations.data;
          vm.response.timezone = response.data.data.timezone;
          vm.request.pagination.page = response.data.data.locations.current_page;
          vm.request.pagination.perPage = response.data.data.locations.per_page;
          vm.request.pagination.total = response.data.data.locations.total;
        })
        .catch((error) => console.error(error.response))
        .finally(() => vm.loaders.locations = false);
    },

    createLocationRequest(data = {value}, id = {value}) {
      let vm = this;
      vm.modals.location.loader = true
      let url, message;
      if (id == null) { // New Location Scenario
        url = this.$store.getters.getBaseUrl + `/api/event-calendar/location/create`
        message = 'Location created successfully'
      } else { // Edit Location Scenario
        url = this.$store.getters.getBaseUrl + `/api/event-calendar/location/create/${id}`
        message = 'Location updated successfully'
      }
      axios.post(url, data)
        .then(() => {
          this.$notify.success({
            title: 'Success',
            message: message
          })
          this.fetchLocationRequest()
          this.$refs.eventLocationModal.handleClose()
        })
        .catch((error) => this.errorRoutine(error))
        .finally(() => vm.modals.location.loader = false);
    },

    deleteRequest(data) {
      this.loaders.checkboxButton.Delete = true
      const payload = {ids: Array.isArray(data) ? data : [data]};
      axios.post(this.$store.getters.getBaseUrl + `/api/event-calendar/location/delete`, payload)
        .then((response) => {
          this.removeId = [];
          this.fetchLocationRequest();
          this.$store.commit("hideRemoveModal");
        })
        .catch((error) => this.errorRoutine(error))
        .finally(() => {
          this.loaders.checkboxButton.Delete = false;
        })
    },

    statusUpdateRequest(ids, status) {
      this.loaders.locations = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
      };
      axios.post(this.$store.getters.getBaseUrl + `/api/event-calendar/location/update`, payload)
        .then((response) => {
          this.removeId = []
          this.fetchLocationRequest()
        })
        .catch((error) => this.errorRoutine(error))
        .finally(()=> this.loaders.locations = false)
    },


  },
  computed: {
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },

    isProgramAdmin() {
      const authUser = this.$store.state.authUser
      return authUser ? authUser.type == 'sub_user' : false
    },

    isProgramUser() {
      const authUser = this.$store.state.authUser
      return authUser ? authUser.type == 'program_user' : false
    },

    getTimeZone() {
      const timezones = {
        'America/New_York': 'Eastern Daylight Time New York (GMT-4)',
        'America/Chicago': 'Central Daylight Time Chicago (GMT-5)',
        'America/Denver': 'Mountain Daylight Time Denver (GMT-6)',
        'America/Phoenix': 'Mountain Standard Time Phoenix (GMT-7)',
        'America/Los_Angeles': 'Pacific Daylight Time Los Angeles (GMT-7)',
        'America/Anchorage': 'Alaska Daylight Time Anchorage (GMT-8)',
        'Pacific/Honolulu': 'Hawaii-Aleutian Standard Time (GMT-10)',
        'Australia/Sydney': 'Australian Eastern Standard Time Sydney (GMT+10)'
      }

      return timezones[this.response.timezone] ?? ''
    }
  },
  mounted() {
    this.fetchLocationRequest()
  }
}
</script>

<style scoped>

.cardStyle {
  background-color: white;
  border-radius: 6px;
  border: 1px solid #00000000;
}

.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.indicator {
  height: 7px;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}

</style>
