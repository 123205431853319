<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <div class="mb-3 d-flex justify-content-between align-items-center">
      <h1 class="m-0">List of Events</h1>
      <router-link class="ml-auto mr-1"
                   :to="{ name: 'Event Locations'}">
        <base-button class="white-outline-button button-font title-btn-padding">
          Location
        </base-button>
      </router-link>
      <router-link class="ml-2"
                   :to="{ name: 'EventFormNew',
                          params: { newFlag: true }}" v-if="response.timezone">
        <base-button class="btn-secondary-action button-font title-btn-padding">
          Add New
        </base-button>
      </router-link>
      <base-button class="btn-secondary-action button-font title-btn-padding" @click="() => showAddTimeZonePopup()" v-else>
          Add New
      </base-button>
    </div>
    <div class="mt-2 cardStyle" v-loading="loaders.checkboxButton || loaders.events">
      <!--   Buttons Div   -->
      <div v-if="selectedRows.length" id="select-option-container" class="mt-5 mb-1 ml-4">
        <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                     @click="promptMultiRemove"
                     :disabled="loaders.removeModal"
        >Delete
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                     @click="handleActivate"
                     :disabled="loaders.checkboxButton"
        >Activate
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                     @click="handleDeactivate"
                     :disabled="loaders.checkboxButton"
        >Deactivate
        </base-button>
      </div>
      <!--   Dropdowns Div   -->
      <div v-else class="row mt-4 mx-1 px-2">
        <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap w-100">
          <div class="d-flex w-85 flex-wrap">
            <div class="col" style="min-width: 200px;">
              <h4 class="form-label text-clip">Month / Year</h4>
              <vue-monthly-picker
                class="event-month-picker"
                @selected="fetchEventRequest"
                dateFormat="MMMM YYYY"
                :clearOption="false"
                placeHolder="Pick a month"
                :monthLabels="monthPickerLabels"
                selectedBackgroundColor="#832C44"
                v-model="params.date">
              </vue-monthly-picker>
            </div>

            <div class="col">
              <h4 class="form-label text-clip">Status</h4>
              <base-input>
                <el-select class="event-dropdown"
                           @change="fetchEventRequest"
                           v-model="params.status"
                           clearable placeholder="All">
                  <el-option v-for="option in statusOptions"
                             :key="option.label"
                             :label="option.label"
                             :value="option.value">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <!--    Henry Ford Drop Menu    -->
            <div class="col">
              <h4 class="form-label text-clip">Weight Loss Type</h4>
              <base-input>
                <el-select class="event-dropdown"
                           @change="fetchEventRequest"
                           v-model="params.weight_loss_type" clearable placeholder="Select type">
                  <el-option value="" label="All"></el-option>
                  <el-option value="1" label="Surgical"></el-option>
                  <el-option value="2" label="Non-surgical"></el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col">
              <h4 class="form-label text-clip">Venue Type</h4>
              <base-input>
                <el-select class="event-dropdown"
                           @change="fetchEventRequest"
                           v-model="params.venue_type" clearable placeholder="Select type">
                  <el-option value="" label="All"></el-option>
                  <el-option value="1" label="In-Person"></el-option>
                  <el-option value="2" label="Virtual"></el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col">
              <h4 class="form-label text-clip">Event Type</h4>
              <base-input>
                <el-select class="event-dropdown"
                           @change="fetchEventRequest"
                           v-model="params.eventType" clearable placeholder="All">
                  <el-option value="1" label="Fixed Date"></el-option>
                  <el-option value="2" label="Weekly"></el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col" v-loading="loaders.locations">
              <h4 class="form-label text-clip">Location</h4>
              <base-input>
                <el-select class="event-dropdown"
                           @change="fetchEventRequest"
                           v-model="params.location" clearable placeholder="All">
                  <template v-for="location in response.locations">
                    <el-option :value="String(location.id)" :label="location.name" :key="location.id"></el-option>
                  </template>
                  <el-option value="0" label="Other"></el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col">
              <h4 class="form-label text-clip">Hosted By</h4>
              <base-input>
                <el-select class="event-dropdown"
                           @change="fetchEventRequest"
                           v-model="params.importedEvents" clearable placeholder="All">
                  <el-option value="0" label="Our Events Only"></el-option>
                  <el-option value="1" label="BA Virtual Support Groups"></el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div>
            <el-tooltip
              effect="dark"
              placement="top-end"
              popper-class="tooltip-popper-class-width-400"
            >
              <div slot="content" class="lh-150">
                <b>Bariatric Advantage offers regular free virtual support groups hosted by our Registered
                Dietitian.</b> Patients learn the why behind the what and walk away with practical strategies to implement
                for success. If you'd like to add these events to your calendar, toggle this on.
              </div>
              <div>
                <h4 class="form-label text-clip">Import BA Events</h4>
                <base-switch class="float-right mt-2"
                             v-model="response.importEventStatus"
                             onText=""
                             offText=""
                             @input="toggleImportEvents"
                             @click="showAddTimeZonePopup(response.importEventStatus)"
                             :disabled="!response.timezone && !response.importEventStatus"
                             />
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <!--   Table   -->
      <el-table
        ref="multipleTable"
        header-row-class-name="thead-light"
        class="show-hidden-scrollbar event-list-table"
        :data="response.events.data"
        style="width: 100%"
        @selection-change="alertsMultiSelect"
        :row-class-name="tableRowClassName" >
        <el-table-column
          v-for="(column,index) in tableColumns"
          :key="column.label"
          v-bind="column"
        >
          <template v-if="index===6" scope="scope">
              <span :class="[column.prop, getColumn(scope.row, column)]">
                <div class="d-inline-block indicator" :class="getColumn(scope.row, column)"></div>
                {{ getColumn(scope.row, column) }}
              </span>
          </template>
          <template v-else-if="index!==0" scope="scope">
            <span>{{ getColumn(scope.row, column) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-bind="lastColumn">
          <div slot-scope="{ $index, row }" class="d-flex">
            <el-tooltip
              class="item" effect="dark"
              content="View Event"
              placement="bottom-start"
              :visible-arrow="false"
            >
              <img
                class="pointer mr-2"
                @click="handleShow($index)"
                src="/img/icons/buttons/show.svg"
                alt="Show"
              />
            </el-tooltip>
            <el-tooltip
              class="item" effect="dark"
              content="Copy Event"
              placement="bottom-start"
              :visible-arrow="false"
            >
              <img v-if="response.timezone"
                class="pointer mr-2"
                @click="handleCopy($index)"
                src="/img/icons/buttons/copy.svg"
                alt="Show"
              />
              <img v-else
                class="pointer mr-2"
                @click="() => showAddTimeZonePopup()"
                src="/img/icons/buttons/copy.svg"
                alt="Show"
              />
            </el-tooltip>
            <el-tooltip
              v-if="row.admin_imported == 0"
              class="item" effect="dark"
              content="Edit Event"
              placement="bottom-start"
              :visible-arrow="false"
            >
              <img
                v-if="row.admin_imported == 0 && response.timezone"
                class="pointer mr-2"
                @click="handleEdit($index, row)"
                src="/img/icons/buttons/edit.svg"
                alt="Edit"
              />
              <img
                v-else-if="row.admin_imported == 0 && !response.timezone"
                class="pointer mr-2"
                @click="() => showAddTimeZonePopup()"
                src="/img/icons/buttons/edit.svg"
                alt="Edit"
              />
            </el-tooltip>
            <el-tooltip
              class="item" effect="dark"
              content="Delete Event"
              placement="bottom-start"
              :visible-arrow="false"
            >
              <img
                class="pointer"
                @click="handleDelete($index, row)"
                src="/img/icons/buttons/delete.svg"
                alt="Delete"
              />
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
      <div class="pt-5 w-100 d-flex justify-content-end">
        <custom-pagination
          class="pr-5"
          :total="response.events.total"
          v-model="response.events.current_page"
          :per-page="response.events.per_page"
          @input="changePage"
        >
        </custom-pagination>
      </div>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeCategory"
        @onRemoveMultiple="deleteCategory"
      />
    </div>
  </div>
</template>

<script>

import {Option, Select, Table, TableColumn} from "element-ui";
import {BasePagination} from "@/components";
import TableWithActionsAndPagination from "@/components/Tables/TableWithActionsAndPagination";
import CustomPagination from "../../Components/Pagination/CustomPagination";
import moment from "moment";
const RemoveModal = () => import("@/components/Modals/RemoveModal.vue");
import {constants} from "@/util/constants";
import authHelper from "@/util/authHelper";

export default {
  name: "EventList",
  components: {
    CustomPagination,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    CategoryTemplate: TableWithActionsAndPagination,
    RemoveModal,
  },
  computed: {
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
    getDateObject(){
      const date = new Date(this.params.date)
      return {year:date.getFullYear(),month:date.getMonth()+1}
    }
  },
  data(){
    return {
      loaders: {
        events: false,
        removeModal: false,
        checkboxButton: false,
        locations: false
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      response: {
        events: {
          data: [],
          current_page:1,
          per_page:10,
          total:0,
        },
        locations: [],
        importEventStatus: false,
        timezone: null
      },
      tableColumns: [
        {
          prop: "selected",
          type: 'selection',
          width: 50,
        },
        {
          prop: "event_title",
          label: "Event Name",
          minWidth: 160,
        },
        {
          prop: "location_id",
          label: "Location",
          minWidth: 180,
        },
        {
          prop: "location",
          label: "Address",
          minWidth: 180,
        },
        {
          prop: "type",
          label: "Event Type",
          minWidth: 180,
        },
        {
          prop: "venue_type",
          label: "Venue",
          minWidth: 180,
        },
        {
          prop: "weight_loss_type",
          label: "Weight Loss Plan",
          minWidth: 180,
        },
        {
          prop: "date",
          label: "Date",
          minWidth: 180,
        },
        {
          prop: "status",
          label: "Status",
          minWidth: 110,
        },
      ],
      lastColumn: {
        label: "Actions",
        minWidth: 170,
      },
      params: {
        date: '',
        status: '',
        weightType: '',
        eventType: '',
        location: '',
        weight_loss_type: '',
        venue_type: '',
        importedEvents: '',
        page: 1
      },
      venue_types: {
        '': 'All',
        '1': 'In-Person',
        '2': 'Virtual',
      },
      weight_loss_types:{
        '': 'All',
        '1': 'Surgical',
        '2': 'Non-Surgical',
      },
      monthPickerLabels: constants.monthPickerLabels,
      statusOptions: constants.common.statusOptions
    }
  },
  methods: {
    showAddTimeZonePopup(forToggle = false) {
      if (!this.response.timezone && !forToggle) {
        this.$notify.error({
          title: 'Event',
          message: 'Please contact Baritastic support to update your timezone.'
      })
      }
    },
    toggleImportEvents(status) {
      let vm = this
      this.$store.dispatch('_updateImportAdminEventsStatus', {status})
        .then(response => {
          this.$notify.success({
            title: 'Success',
            message: response.data.message
          })
          vm.fetchEventRequest()
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Message',
            message: message
          })
        })
    },

    removeCategory(id) {
      this.deleteRequest(id)
    },
    deleteCategory() {
      this.deleteRequest(this.removeId)
    },
    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },
    handleLongString(longString) {
      return (longString.length > 15) ? longString.slice(0, 15) + '...' : longString
    },
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },
    promptRemove(row) {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove this Event.';
      this.removeId = row.id;
    },
    promptMultiRemove() {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove these Events.';
      this.removeId = this.extractSelectedIds;
    },
    tableRowClassName({row}) {
        if (row.date < moment().format('YYYY-MM-DD')) {
          return 'before-date'
        }
        return '';
    },
    getColumn(row, column) {
      if (column.prop === 'status') {
        return this.getStatus(row.status);
      }else if (column.prop === 'date') {
        return moment(row.date).format('MMMM DD, YYYY');
      }else if (column.prop === 'type'){
        return (row.type != '1')? 'Weekly' : 'Fixed Date';
      }else if (column.prop === 'location_id') {
        return (row.location_relation == null)? 'Other': row.location_relation.name
      }else if (column.prop === 'venue_type') {
        return this.venue_types[row.venue_type]
      }else if (column.prop === 'weight_loss_type') {
        return this.weight_loss_types[row.weight_loss_type]
      } else {
        return row[column.prop]
      }
    },
    getStatus(status) {
      if (status === 0) {
        return 'Inactive'
      }
      return 'Active';
    },
    handleShow(index) {
      this.$router.push({
        name: 'EventDetail',
        params: {
          id : this.response.events.data[index].id
        }
      })
    },
    handleEdit(index) {
      this.$router.push({
        name: 'EventFormEdit',
        params: {
          newFlag: false,
          event: this.response.events.data[index],
          id: this.response.events.data[index].id
        }
      })
    },
    handleCopy(index) {
      this.$router.push({
        name: 'EventFormEdit',
        params: {
          event: this.response.events.data[index],
          id: this.response.events.data[index].id,
          copyFlag: true,
        }
      })
    },
    handleDelete(index, row) {
      this.promptRemove(row)
    },
    alertsMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
    },
    clearSelection() {
      this.$refs.multipleTable.clearSelection();
    },
    changePage(page) {
      this.params.page = page
      this.fetchEventRequest()
    },

    /**
     * Get recently saved API params for search
     */
    getSavedDateParamsForSearch() {
      const token = authHelper.getAuthToken('subUser')
      if (token) {
        const savedSearch = authHelper.getEventCalendarSavedSearch()
        if (savedSearch) {
          this.params.date = savedSearch
        }
      }
    },

    /**
     * Reset calendar search and save new params
     */
    resetAndSaveDateParamForFutureSearch() {
      authHelper.deleteEventCalendarSavedSearch()
      authHelper.setEventCalendarSavedSearch(this.params.date)
    },

    fetchEventRequest() {
      this.resetAndSaveDateParamForFutureSearch()
      this.loaders.events = true
      this.$store.commit('setCancellationToken', 'fetchEventRequest');
      const config = {
        params: Object.assign({},this.params),
        cancelToken: this.$store.state.cancelRequests['fetchEventRequest'].token,

      }
      config.params.date = this.getDateObject
      axios.get(this.$store.getters.getBaseUrl + '/api/event-calendar',config)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.response.events = response.data.data.events
          this.response.importEventStatus = response.data.data.importEventStatus ?? false
        })
        .catch((error) => {
          if(!axios.isCancel(error)){
            this.$notify.error({
            title: 'Error',
            message: 'Something went wrong try again later'
          });
        }
      })
        .finally(() => this.loaders.events = false)
    },
    deleteRequest(data) {
      this.loaders.removeModal = true
      const payload = {
        ids: Array.isArray(data) ? data : [data],
        _method: 'DELETE',
      }
      axios.post(this.$store.getters.getBaseUrl + `/api/event-calendar/remove`, payload)
        .then(() => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.removeId = []
          this.fetchEventRequest()
          this.$store.commit("hideRemoveModal");
        })
        .catch((error) => console.error(error.message))
        .finally(()=> this.loaders.removeModal = false)
    },
    statusUpdateRequest(ids, status) {
      this.loaders.checkboxButton = true
      const payload = {
        ids: Array.isArray(ids) ? ids : [ids],
        status: status,
        _method: 'PATCH',
      }
      axios.post(this.$store.getters.getBaseUrl + `/api/event-calendar/toggle`, payload)
        .then(() => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.removeId = []
          this.fetchEventRequest()
        })
        .catch((error) => console.error(error.message))
        .finally(()=> this.loaders.checkboxButton = false)
    },
    fetchLocationsRequest() {
      this.loaders.location = true
      axios.get(this.$store.getters.getBaseUrl + '/api/event-calendar/location')
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.response.locations = response.data.data.locations.data
          this.response.timezone = response.data.data.timezone ?? null
        })
        .catch(() => {
          this.$notify.error({
            title: 'Error',
            message: 'Event might have been deleted'
          })
          this.$router.replace('/event-calender')
        })
        .finally(() => this.loaders.location = false)
    },
  },
  mounted() {
    this.fetchLocationsRequest()
    this.params.date = new Date(Date.now()).toISOString();
    this.getSavedDateParamsForSearch();
    this.fetchEventRequest();
  },
}
</script>

<style scoped lang="scss">
/* Explicitly showing scroll bar even if gets hide */
.show-hidden-scrollbar >>> .el-table__body-wrapper{
  overflow: auto !important;
}

.form-label{
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0;
  color: #525F7F;
}

.text-clip{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardStyle {
  background-color: white;
  border-radius: 6px;
  border: 1px solid #00000000;;
}

.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.indicator {
  height: 7px;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}

.event-month-picker {
  ::v-deep .date-popover {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    min-width: 280px;
  }

  ::v-deep .month-picker-wrapper{
    min-width: 100px !important;
  }

  ::v-deep .month-year-display {
    height: calc(1.5em + 1.25rem - 1px);
    color: #606266;
    font-size: 14px;
    font-weight: 400;
  }
}

</style>
<style lang="scss">
.event-list-table {
  tr {
    &.before-date {
      background-color: #e9ecef8f;
      &:hover {
        background-color: #e9ecef8f;
      }
    }
  }
}
</style>
